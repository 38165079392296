export const svgs = {
  // SOCIAL MEDIA
  facebook: {
    path: [
      "M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z",
    ],
    title: "Facebook",
  },

  github: {
    path: [
      "M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z",
    ],
    title: "Github",
  },

  instagram: {
    path: [
      "M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z",
    ],
    title: "Instagram",
  },

  linkedIn: {
    path: [
      "M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z",
    ],
    title: "Linked In",
  },

  tripAdvisor: {
    path: [
      "M23.011 9.532c.281-1.207 1.175-2.416 1.175-2.416h-4.012c-2.251-1.455-4.981-2.226-8.013-2.226-3.14 0-5.978.78-8.214 2.251H.186s.885 1.186 1.17 2.386C.624 10.534.189 11.749.189 13.084c0 3.316 2.697 6.008 6.012 6.008 1.891 0 3.571-.885 4.681-2.254l1.275 1.916 1.291-1.936c.57.736 1.32 1.336 2.205 1.74 1.455.66 3.092.736 4.592.18 3.106-1.154 4.696-4.621 3.556-7.726-.209-.556-.48-1.051-.81-1.485l.02.005zm-3.171 8.072c-1.2.445-2.505.395-3.67-.143-.824-.383-1.503-.982-1.988-1.727-.201-.299-.375-.623-.503-.971-.146-.395-.22-.803-.259-1.215-.074-.832.045-1.673.405-2.453.54-1.164 1.501-2.051 2.701-2.496 2.49-.914 5.25.361 6.166 2.841.916 2.481-.36 5.245-2.835 6.163h-.017zm-9.668-1.834c-.863 1.271-2.322 2.113-3.973 2.113-2.646 0-4.801-2.156-4.801-4.797 0-2.641 2.156-4.802 4.801-4.802s4.798 2.161 4.798 4.802c0 .164-.03.314-.048.479-.081.811-.341 1.576-.777 2.221v-.016zM3.15 13.023c0 1.641 1.336 2.971 2.971 2.971s2.968-1.33 2.968-2.971c0-1.635-1.333-2.964-2.966-2.964-1.636 0-2.971 1.329-2.971 2.964H3.15zm12.048 0c0 1.641 1.329 2.971 2.968 2.971 1.636 0 2.965-1.33 2.965-2.971 0-1.635-1.329-2.964-2.965-2.964-1.635 0-2.971 1.329-2.971 2.964h.003zm-11.022 0c0-1.071.869-1.943 1.936-1.943 1.064 0 1.949.873 1.949 1.943 0 1.076-.869 1.951-1.949 1.951-1.081 0-1.951-.875-1.951-1.951h.015zm12.033 0c0-1.071.869-1.943 1.949-1.943 1.066 0 1.937.873 1.937 1.943 0 1.076-.87 1.951-1.952 1.951-1.079 0-1.949-.875-1.949-1.951h.015zM12.156 5.94c2.161 0 4.111.389 5.822 1.162-.645.018-1.275.131-1.906.36-1.515.555-2.715 1.665-3.375 3.125-.315.66-.48 1.359-.541 2.065-.225-3.076-2.76-5.515-5.881-5.578C7.986 6.34 9.967 5.94 12.112 5.94h.044z",
    ],
    title: "Trip Advisor",
  },

  twitter: {
    path: [
      "M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z",
    ],
    title: "Twitter",
  },

  yelp: {
    path: [
      "M21.111 18.226c-.141.969-2.119 3.483-3.029 3.847-.311.124-.611.094-.85-.09-.154-.12-.314-.365-2.447-3.827l-.633-1.032c-.244-.37-.199-.857.104-1.229.297-.359.732-.494 1.111-.35.02.012 1.596.531 1.596.531 3.588 1.179 3.705 1.224 3.857 1.338.227.186.332.475.285.813h.006zm-7.191-5.267c-.254-.386-.25-.841.012-1.155l.998-1.359c2.189-2.984 2.311-3.141 2.459-3.245.256-.171.57-.179.871-.032.869.422 2.623 3.029 2.729 4.029v.034c.029.341-.105.618-.346.784-.164.105-.314.166-4.393 1.156-.645.164-1.004.254-1.215.329l.029-.03c-.404.12-.854-.074-1.109-.479l-.035-.032zm-2.504-1.546c-.195.061-.789.245-1.519-.938 0 0-4.931-7.759-5.047-7.998-.07-.27.015-.574.255-.82.734-.761 4.717-1.875 5.76-1.621.34.088.574.301.656.604.06.335.545 7.536.615 9.149.066 1.38-.525 1.565-.72 1.624zm.651 7.893c-.011 3.774-.019 3.9-.081 4.079-.105.281-.346.469-.681.53-.96.164-3.967-.946-4.594-1.69-.12-.164-.195-.328-.21-.493-.016-.12 0-.24.045-.346.075-.195.18-.345 2.88-3.51l.794-.944c.271-.345.75-.45 1.199-.271.436.165.706.54.676.945v1.68l-.028.02zm-8.183-2.414c-.295-.01-.56-.187-.715-.48-.111-.215-.189-.57-.238-1.002-.137-1.301.029-3.264.419-3.887.183-.285.45-.436.745-.426.195 0 .369.061 4.229 1.65l1.13.449c.404.15.654.57.63 1.051-.03.465-.298.824-.694.93l-1.605.51c-3.59 1.155-3.709 1.185-3.898 1.17l-.003.035zm14.977 7.105h-.004l-.005.003.009-.003z",
    ],
    title: "Yelp",
  },

  youtube: {
    path: [
      "M20,0S7.48,0,4.35.82A5.16,5.16,0,0,0,.82,4.38C0,7.51,0,14,0,14s0,6.53.82,9.62a5,5,0,0,0,3.53,3.53C7.51,28,20,28,20,28s12.52,0,15.65-.83a5,5,0,0,0,3.52-3.52C40,20.53,40,14,40,14s0-6.53-.83-9.66A5,5,0,0,0,35.65.86C32.52,0,20,0,20,0ZM16,8l10.41,6L16,20Z",
    ],
    title: "YouTube",
  },

  // GENERIC
  arrow: {
    path: ["M6 0l12 12-12 12z"],
    title: "Arrow",
  },

  close: {
    path: [
      "M0.927,5.397c-1.235-1.235-1.235-3.236,0-4.471l0,0c1.233-1.234,3.235-1.235,4.47,0l0,0l37.25,37.25     c1.234,1.233,1.233,3.235-0.001,4.47l0,0c-1.234,1.234-3.235,1.234-4.47,0.001l0,0L0.927,5.397L0.927,5.397z",
      "M5.397,42.647c-1.234,1.234-3.236,1.234-4.471-0.001l0,0c-1.235-1.234-1.234-3.235,0.001-4.47l0,0     l37.248-37.25c1.235-1.234,3.236-1.236,4.471-0.001l0,0c1.235,1.234,1.235,3.237,0,4.472l0,0L5.397,42.647L5.397,42.647z",
    ],
    title: "Close",
  },

  email: {
    path: [
      "M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z",
    ],
    title: "Email",
  },

  hamburgerMenu: {
    path: [
      "M9.7266 0H0.5C0.367392 0 0.240215 0.0526784 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5C0 0.632608 0.0526784 0.759785 0.146447 0.853553C0.240215 0.947322 0.367392 1 0.5 1H9.7266C9.85921 1 9.98639 0.947322 10.0802 0.853553C10.1739 0.759785 10.2266 0.632608 10.2266 0.5C10.2266 0.367392 10.1739 0.240215 10.0802 0.146447C9.98639 0.0526784 9.85921 0 9.7266 0Z",
      "M9.7266 3H0.5C0.367392 3 0.240215 3.05268 0.146447 3.14645C0.0526784 3.24021 0 3.36739 0 3.5C0 3.63261 0.0526784 3.75979 0.146447 3.85355C0.240215 3.94732 0.367392 4 0.5 4H9.7266C9.85921 4 9.98639 3.94732 10.0802 3.85355C10.1739 3.75979 10.2266 3.63261 10.2266 3.5C10.2266 3.36739 10.1739 3.24021 10.0802 3.14645C9.98639 3.05268 9.85921 3 9.7266 3Z",
      "M10.2266 6.5C10.2266 6.36739 10.1739 6.24021 10.0802 6.14645C9.98639 6.05268 9.85921 6 9.7266 6H0.5C0.367392 6 0.240215 6.05268 0.146447 6.14645C0.0526784 6.24021 0 6.36739 0 6.5C0 6.63261 0.0526784 6.75979 0.146447 6.85355C0.240215 6.94732 0.367392 7 0.5 7H9.7266C9.85921 7 9.98639 6.94732 10.0802 6.85355C10.1739 6.75979 10.2266 6.63261 10.2266 6.5Z",
    ],
    title: "Navigation",
  },

  sms: {
    path: [
      "M78.48,0h-57A21.77,21.77,0,0,0,0,22V43.69a21.76,21.76,0,0,0,21.52,22h36L83.7,87.07V65A21.86,21.86,0,0,0,100,43.69V22A21.77,21.77,0,0,0,78.48,0ZM64.33,42.82H35.64V36.57H64.33Zm14.1-14.5H21.54V22.07H78.43Z",
    ],
    title: "SMS",
  },
}

export default svgs
