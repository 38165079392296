export const colors = {
  navy: "#242E3C",
  red: "#CE3240",
  gold: "#C3996B",
  linen: "#f3ebe2",
  white: "#FFFFFF",
  dark: "#000000",

  fonts: {
    light: "#FFFFFF",
    dark: "#555555",
  },
}
